body {
	margin: 0;
	box-sizing: border-box;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

html {
	@apply text-darkBlue;
}

::selection {
	color: white;
	background: #071D58;
}

@tailwind base;

@tailwind components;

@tailwind utilities;
